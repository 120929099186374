.footer {
    @extend .container;

    .footer-content {
        padding-top: 30px;
        padding-bottom: 20px;

        ul {
            list-style: none;
            padding: 0 30px 0;
            display: flex;
            justify-content: flex-end;

            @media (max-width: $screen-md) {
                padding: 0 15px;
                justify-content: flex-start;
            }

            @media (max-width: 320px) {
                flex-wrap: wrap;
            }

            li {
                padding-right: 15px;

                &.current,
                &.active {
                  color: $brand-primary;
                }

                &:last-child {
                    padding-right: 0;
                }

                a {
                    color: inherit;
                    font-weight: 500;
                    text-decoration: none;
                    position: relative;

                    @media (min-width: $screen-md) {
                      &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: -18px;
                        width: 100%;
                        height: 12px;
                        background-image: url("/_Resources/Static/Packages/Lindenschule.NusslochDe/Images/layout/nav-hover.png");
                        background-repeat: no-repeat;
                        background-position: 60%;
                        transition: $transition-duration opacity;
                        opacity: 0;
                      }

                      &:hover::after {
                        opacity: 1;
                      }
                    }
                }
            }
        }
    }
}
