/*
  Grid content

  | Columns  | Category  |  Class      |
  --------------------------------------
  1  column    Small-       .col-1
  2  columns   Small        .col-2
  3  columns   Small+       .col-3
  - - - - - - - - - - - - - - - - - - -
  4  columns   Normal-      .col-4
  5  columns   Normal       .col-5
  6  columns   Normal+      .col-6
  - - - - - - - - - - - - - - - - - - -
  7  columns   Medium-      .col-7
  8  columns   Medium       .col-8
  9  columns   Medium+      .col-9
  - - - - - - - - - - - - - - - - - - -
  10 columns   Large-       .col-10
  11 columns   Large        .col-11
  12 columns   Large+       .col-12
  --------------------------------------
*/

.grid-content {
  .grid-content-col {
    @for $i from 1 to 12 {
      &.col-#{$i} {
        @include make-md-column($i);
      }
    }

    @for $i from 1 to 12 {
      &.col-offset-#{$i} {
        @include make-md-column-offset($i);
      }
    }
  }
}

// From bootstrap/_grid.scss
.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

// From bootstrap/_grid.scss
.row {
  @include make-row;
}
