body {
  font-weight: 300;
}

h1 {
  font-weight: 300;
  margin-bottom: 23px;
}

h2 {
  color: $color-darkgreen;
}

h3 {
  font-size: $font-size-base;
}

p {
  a {

    color: black;
    position: relative;
    text-decoration: none;
    background:linear-gradient($color-darkgreen,$color-darkgreen) left 17px no-repeat;
    background-size: 100% 1px;
    transition: all 0.5s;
    
    &:hover {
      text-decoration: none;
    }
  }
}

body  > .content {
  min-height: 70vh;

  img {
    @extend .img-responsive;
    max-width: 600px;
  }

  @media (max-width: $screen-sm-max) {
    padding-top: 20px;
    padding-bottom: 20px;

    img {
      max-width: 100%;
    }
  }

  @media (min-width: $screen-md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.btn-primary {
  transition-property: border-color, background-color ;
  transition-duration: $transition-duration;

  &:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
  }
}

// bootstrap/_navbar.scss
.navbar-toggle {
  position: relative;
  float: right;
  margin-right: $navbar-padding-horizontal;
  padding: 9px 10px;
  @include navbar-vertical-align(34px);
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  border-radius: $border-radius-base;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  // Bars
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

@media (min-width: $screen-lg) {
  .header, .callout, .content, .footer {
    $width: 3px;
    $blur: 2px;
    $color: $color-gray;
    $offset: $grid-gutter-width/2;
    background-image:
      linear-gradient(90deg, transparent $offset - $width, $color $offset - $width + $blur, $color $offset, transparent $offset),
      linear-gradient(-90deg, transparent $offset - $width, $color $offset - $width + $blur, $color $offset, transparent $offset);
    background-size: 100%;
    background-clip: padding-box;
    background-repeat: no-repeat;
    overflow: auto;
  }
}

.callout {
  display: none;
  overflow: visible;

  @media (min-width: $screen-sm) {
    display: block;
  }

  .item {
    position: relative;
    width: 100%;
    height: 360px;
    background-repeat: no-repeat;
    background-size: contain;

    figure {
        img {
            height: auto;
            width: auto;
            max-width: 100%;
        }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      right: -20px;
      width: 60px;
      height: 57px;
      background-image: url("/_Resources/Static/Packages/Lindenschule.NusslochDe/Images/layout/callout-decoration.png");
      transition: transform $transition-duration;
      transform: rotate(-1deg);
    }

    &:hover::after {
      transform: rotate(-15deg);
    }
  }

  @media (max-width: $screen-sm-max) {
    max-height: 227px;

    .item {
      max-height: 227px;
    }
  }

  @media (max-width: $screen-xs-max) {
    display: block;
    max-height: 231px;
    width: 100%;

    .item {
      max-height: 231px;
      background-size: contain;

      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    max-height: 116px;
  }

  @media (max-width: 360px) {
    max-height: 92px;
  }
}

.neos-nodetypes-image + .neos-nodetypes-image {
  margin-top: $line-height-computed;
}

article.content-main {
  margin-bottom: $line-height-computed;
}

.neos-contentcollection > .neos-nodetypes-headline:first-child h1 {
  margin-top: 0;
}
