//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$grid-float-breakpoint: 992px;
$container-large-desktop: (1140px + $grid-gutter-width);
$container-desktop: $container-large-desktop;

// Fonts
$font-family-sans-serif: "Museo-Sans", sans-serif;
$font-size-base: 15px;
$font-size-h1: 32px;
$font-size-h2: 19px;

// Colors
$color-white: #ffffff;
$color-black: #3c3c3b;
$color-gray: #e6e6e6;
$color-darkgreen: #65a930;
$color-lightgreen: #a4c054;
$brand-primary: $color-darkgreen;
$brand-secondary: $color-lightgreen;
$text-color: $color-black;

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;
$btn-primary-bg: $brand-secondary;
$btn-primary-border: $brand-secondary;

// Animations
$transition-duration: .3s;
