.content {
  .content-nav {
    ul {
      list-style-type: none;
      padding: 0;
      margin-right: 12px;
    }

    li {
      padding-left: 25px;
      border-top: 1px dashed $brand-secondary;
      line-height: 2;

      &:last-child {
        border-bottom: 1px dashed $brand-secondary;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      &.current a {
        color: $color-darkgreen;
      }
    }
  }

  .content-main {
    ul {
      padding-left: 20px;
    }

    @media (max-width: $screen-sm-max) {
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .content-aside {
    video {
      max-width: 100%;
    }

    .btn {
      width: 100%;
      text-align: left;
    }

    .btn + .btn {
      margin-top: $line-height-computed / 2;
    }

    > .contact {
      margin-bottom: 1em;
    }
  }
}
