.contact-form {
  @media (min-width: $screen-md) {
    padding-right: $grid-gutter-width / 2;
  }

  .form-group {
    @include make-sm-column(12);
  }

  .contact-form-firstname,
  .contact-form-lastname {
    @include make-sm-column(6);
  }

  .contact-form-submit {
    @include make-md-column(6);
  }

  @media (min-width: $screen-sm) {
    .contact-form-firstname {
      padding-right: 0;
    }
  }

  @media (max-width: $screen-sm-max) {
    .form-group {
      padding: 0;
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .contact-form-firstname {
      padding-left: 0;
      padding-right: $grid-gutter-width / 2;
    }

    .contact-form-lastname {
      padding-right: 0;
    }
  }

  .contact-form-confirmation,
  .contact-form-error {
    display: none;
    border: 0;
    border-radius: 0;

    &.alert-success {
      background-color: $brand-primary;
      color: $color-white;
    }

    b {
      font-weight: bold;
    }

    .debug {
      font-size: $font-size-small;
    }
  }
}
