$header-logo-height-md: 50px;
$header-logo-height-xs: $header-logo-height-md;

.header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 22;

    &::after {
        content: '';
        position: absolute;
        left: 15px;
        bottom: 1px;
        box-shadow: 0 4px 2px -2px rgb(182, 182, 182);
        box-shadow: 0 2px 1px -2px #b6b6b6;
        width: calc(100% - 30px);
        height: 5px;

        @media (max-width: $screen-sm-max) {
            left: 0;
            width: 100%;
        }
    }

    // box-shadow: 0 4px 2px -2px rgb(182, 182, 182);

  @media (max-width: $screen-sm-max) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (min-width: $screen-md) {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .header-logo {
    @include make-md-column(4);

    // Fit logo and toggle in one row
    position: absolute;

    @media (min-width: $screen-md) {
      position: relative;
      left: 25px;
    }

    img {
      height: $header-logo-height-xs;

      @media (min-width: $screen-md) {
        height: $header-logo-height-md;
      }
    }
  }

  .header-nav {
    @include make-md-column(8);

    @media (min-width: $screen-md) {
      margin-top: 34px;
    }

    .header-nav-list {
      list-style-type: none;
    }

    .header-nav-list {
      @include list-unstyled;

      @media (min-width: $screen-md) {
        position: absolute;
        right: $grid-gutter-width;
        padding: 0;
      }

      @media (max-width: $screen-sm-max) {
        // Add extra padding so that list does not overlay logo
        padding-top: $header-logo-height-xs+20px;

        font-size: $font-size-large;
      }

      li {
        position: relative;

        @media (min-width: $screen-md) {
          display: inline-block;
        }

        a {
          color: inherit;
          font-weight: 500;
          text-decoration: none;

          @media (min-width: $screen-md) {
            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: -18px;
              width: 100%;
              height: 12px;
              background-image: url("/_Resources/Static/Packages/Lindenschule.NusslochDe/Images/layout/nav-hover.png");
              background-repeat: no-repeat;
              background-position: 60%;
              transition: $transition-duration opacity;
              opacity: 0;
            }

            &:hover::after {
              opacity: 1;
            }
          }
        }

        &.current,
        &.active {
          color: $brand-primary;
        }
      }

      @media (min-width: $screen-md) {
        li + li {
          &::before {
            content: "|";
            padding: 0 8px;
            color: $color-black;
          }
        }
      }
    }

    .header-nav-toggle {
      position: relative;
      top: 4px;
      color: #000;

      button {
        border-color: #000;
        margin-right: 0;
      }

      span {
        background-color: #000;
      }
    }

    @media (min-width: $grid-float-breakpoint) {
      .header-nav-collapse.collapse {
        display: block;
        visibility: visible;
        height: auto;
        overflow: visible;
      }
    }
  }

  li:hover:after {
    display: block;
  }
}
